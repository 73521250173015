<template>
  <div class="areamap">
    <van-search v-model="key" placeholder="请输入搜索关键词" clearable />
    <div class="list-wrap">
      <van-cell-group inset>
        <van-cell v-for="(item, index) in list" :key="index" :title="item.name" value=">" clickable @click="openLocation(item)" :label="`距您${item.distance || '-'}公里`" />
      </van-cell-group>
    </div>
  </div>
</template>
<script>
import wx from 'weixin-js-sdk'
import sha1 from 'sha1'
export default {
  data() {
    return {
      key: '',
      raw: [],
      latitude: '',
      longitude: ''
    }
  },
  computed: {
    list() {
      return this.raw.filter(item => item.name.indexOf(this.key) > -1 )
    }
  },
  mounted() {
    this.$http.get('/accessToken/getTicket').then(res => {
      this.initWx(res.data)
    })
  },
  methods: {
    initWx(data) {
      const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      const maxPos = $chars.length;
      let nonceStr = ''
      for (let index = 0; index < 16; index++) {
        nonceStr += $chars.charAt(Math.floor(Math.random() * maxPos))
      }
      const timestamp = parseInt(Date.parse(new Date())/1000)
      wx.config({
        debug: false,
        appId: 'wxd74edb5408c7d423',
        timestamp: timestamp,
        nonceStr: nonceStr,
        signature: sha1('jsapi_ticket=' + data + '&noncestr=' + nonceStr + '&timestamp=' + timestamp + '&url=' + localStorage.getItem('tmp')),
        jsApiList: ['openLocation','getLocation']
      })
      let that = this
      wx.ready(function(){
        wx.getLocation({
          type: 'wgs84', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
          success: function (res) {
            that.latitude = res.latitude
            that.longitude = res.longitude
            that.getPosition()
          },
          fail: function () {
            that.getPosition()
          }
        })
      })
    },
    getPosition() {
      this.$http.get('/map/position').then(res => {
        if (res.code === 0) {
          if (this.latitude && this.longitude) {
            let arr = res.data
            arr.forEach(item => {
              item.distance = this.getDistance(item.lat, item.lng)
            })
            arr.sort((a,b) => {
              return a.distance - b.distance
            })
          }
          this.raw = res.data
        }
      })
    },
    openLocation(item) {
      wx.openLocation({
        latitude: parseFloat(item.lat), // 纬度，浮点数，范围为90 ~ -90
        longitude: parseFloat(item.lng), // 经度，浮点数，范围为180 ~ -180。
        name: item.name, // 位置名
        address: item.name + '回收点', // 地址详情说明
        scale: 15, // 地图缩放级别,整型值,范围从1~28。默认为最大
        infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
      })
    },
    rad(d) {
      return d * Math.PI / 180.0; 
    },
    getDistance(lat1, lng1) {
      var radLat1 = this.rad(lat1);
      var radLat2 = this.rad(this.latitude);
      var a = radLat1 - radLat2;
      var b = this.rad(lng1) - this.rad(this.longitude);
      var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
          Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000; //输出为公里

      // var distance = s;
      // if (parseInt(distance) >= 1) {
      //     distance_str = distance.toFixed(1) + "km";
      // } else {
      //     distance_str = distance * 1000 + "m";
      // }
      s = s.toFixed(2)
      return s
    }
  }
}
</script>
<style lang="less" scoped>
.areamap {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.list-wrap {
  flex: 1;
  width: 100%;
  height: calc( 100% - 27px );
  overflow: scroll;
}
</style>